<script setup>
const { getSettings } = useDatabase()
const { value: sSiteInfo } = useState('site-info')
const { site } = await getSettings()
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${site?.name}` : site?.name || ''
  },
  htmlAttrs: { lang: 'ja' },
  link: [{ rel: 'icon', type: 'image/x-icon', href: site?.favicon || sSiteInfo?.ファビコン?.value }],
  meta: [{ name: 'description', content: site?.description }],
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
