import libphonenumber from 'google-libphonenumber';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PhoneNumberFormat = libphonenumber.PhoneNumberFormat;
const region = 'JP';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      phone: {
        addHyphen: (val = '') => {
          if (!val) return undefined;
          const numStr    = String(val).replace(/-/g, '');
          const number    = phoneUtil.parseAndKeepRawInput(numStr, region);
          const converted = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);

          if (converted) {
            const [code, num] = converted.split(' ')
            return '0'+num
          }
          return converted
        }
      }
    }
  };
});
