export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path.includes('/api')) return

  const sSettings = useState('settings')
  const { getBrowsingHistory, checkSetting, getSettings } = useDatabase()
  const { tokens, data, refresh, signOut } = useAuth()

  const isSetupPage   = to.path.includes('/setup')
  const isRecoverPage = to.path.includes('/recover')
  const isRegistPage  = to.path.includes('/regist')
  const isLoginPage   = to.path.includes('/login')
  const isAdminPage   = to.path.includes('/admin')
  const isPublicPage  = to.path.includes('/pub')
  const isProducerPage = to.path.includes('/producer')
  const isCompletePage = to.path.includes('/complete')

  // console.group('/src/middleware/01.auth.global.js')
  try {
    // 初期設定が完了しているかチェック
    const setupped = await checkSetting()

    if (setupped) { // 設定が完了している場合
      // 完了ページの場合、処理を終了
      if (isCompletePage) return

       // 設定ページの場合、元のページにリダイレクト
      if (isSetupPage) return navigateTo('/')
    } else { // 設定が未完了の場合
      // 設定ページ以外なら設定ページへリダイレクト
      if (!isSetupPage) return navigateTo('/setup')
    }

    if (tokens.value) {
      // ===================================
      // トークンが存在するか確認

      await refresh() // トークンリフレッシュを実行

      // ユーザー設定を取得し、`state:settings`に保存
      sSettings.value = await getSettings().catch(e => ({}))

      if (isPublicPage || isRegistPage || isRecoverPage) {
        // ------------------------------------------------------------
        // 公開ページ、登録ページ、リカバリーページの場合、処理を終了
        return
      } else if (isLoginPage) {
        // ------------------------------------------------------------
        // ログインページの場合、リダイレクト
        return navigateTo(isAdminPage ? '/admin' : '/')
      } else if (isProducerPage) {
        // ------------------------------------------------------------
        // プロデューサーページの場合の処理
        const cuid = to.params.cuid // プロデューサーIDを取得

        // 閲覧履歴から支払い済みかを確認
        const isPaid = await getBrowsingHistory(cuid)
        const isDetailPage = to.path.includes('/detail')
        if (isDetailPage) { // 詳細ページ
          // 未支払いの場合、プロデューサーページにリダイレクト
          if (!isPaid) return navigateTo(`/producer/${cuid}`)
          return
        } else { // 詳細ページ以外
          // 支払い済みなら詳細ページにリダイレクト
          if (isPaid) return navigateTo(`/producer/${cuid}/detail`)
          return
        }
      } else if (isAdminPage) {
        // ------------------------------------------------------------
        // 管理者ページの場合
        if (data.value.type == 'admin') {
          // 管理者のときは何もしない
          return
        } else {
          // ユーザーが管理者でなければエラーを投げる
          return createError({
            statusCode: 403,
            message: '権限がありません。'
          })
        }
      } else {
        return
      }
    } else {
      // ===================================
      // トークンがない場合の処理
      if (isLoginPage) {
        return  // ログインページの場合は何もしない
      } else {
        // ログアウト処理とログインページへのリダイレクトを実行
        return signOut({
          path: `${isAdminPage ? '/admin' : ''}/login`,
          query: { redirect_to: to.path }
        })
      }
    }
  } catch (e) {
    sSettings.value = {}  // 設定をクリア

    if (!isLoginPage) {
      // エラー時にログインページにリダイレクト
      return signOut(isAdminPage ? '/admin/login' : '/login')
    }
  } finally {
    // console.groupEnd('/src/middleware/01.auth.global.js')
  }
})
