<script setup>
const error   = useError()
const route   = useRoute()
const router  = useRouter()
const isAdmin = route.path.includes('/admin')
const layout  = isAdmin ? 'admin-clear' : 'default-clear'
const message = ref()


if (error.value.statusCode == 401) {
  router.replace({
    path: route.path.includes('/admin') ? '/admin/login' : 'login',
    query: { redirect_to: route.path }
  })
}

onMounted(() => {
  switch(error.value.statusCode) {
    case 401: message.value = '認証に失敗しました。'; break;
    case 403: message.value = '権限がありません。'; break;
    case 404: message.value = 'ページが見つかりませんでした。'; break;
    case 500: message.value = '致命的なエラーが発生しました。'; break;
    default: message.value = 'エラーが発生しました。'; break;
  }
})
</script>

<template>
  <NuxtLayout :name="layout">
    <div class="mx-auto text-center h-screen flex items-center justify-center">
      <div class="container flex flex-col px-3 gap-6">
        <h1
          class="font-extrabold tracking-widest text-9xl"
          :class="isAdmin ? 'text-indigo' : 'text-sky'"
        >{{error?.statusCode}}</h1>

        <p class="text-lg">{{ message }}</p>
        <p v-if="error && message != error.message" class="text-xs">{{ error?.message }}</p>

        <div class="text-center">
          <NuxtLink to="/"
            class="btn w-[200px]"
            :class="isAdmin ? 'btn-primary' : 'btn-sky'"
          >トップへ</NuxtLink>
        </div>

        <div
          v-if="error?.stack && error?.code == 500"
          class="text-left mb-12 w-full overflow-auto px-3 py-3 border rounded bg-white"
          v-html="error?.stack"
        ></div>

      </div>
    </div>
  </NuxtLayout>
</template>
