const zipcode = {
  getAddress: zipcode => {
    // http://zipcloud.ibsnet.co.jp/doc/api
    return new Promise((resolve, reject) => {
      $fetch('https://zipcloud.ibsnet.co.jp/api/search', {
        method: 'get',
        query: { zipcode }
      }).then(data => {
        data = JSON.parse(data)
        if (data.results) {
          const [result] = data.results
          resolve(result)
        } else {
          resolve(null)
        }
      }).catch(reject)
    })
  },
  /** ************************************************
   * 郵便番号をハイフン区切りにする関数
   * @param {String} zipcode - 入力される郵便番号
   * @return {String} - ハイフンが追加された郵便番号
   ************************************************ */
  toFormat: zipcode => {
    // 数字のみの文字列に変換
    const cleaned = String(zipcode).replace(/[^0-9]/g, '');

    // 7桁でない場合はそのまま返す
    if (cleaned.length !== 7) return zipcode;

    // 3桁と4桁の部分にハイフンを挿入
    return cleaned.slice(0, 3) + '-' + cleaned.slice(3, 7);
  },

  isValid: zipcode => {
    const regex = /^(\d{7})$|^(\d{3}-\d{4})$/;
    return regex.test(zipcode);
  }
}

export default zipcode