import Papa from 'papaparse'
import iconv from 'iconv-lite'
import chardet from 'chardet'


export default defineNuxtPlugin(() => {

  const upload = {
    producer: async (file) => {
      const { addProducer } = useDatabase()

      try {
        const rows = await parse(file)
        console.log(rows)
        await addProducer(rows)
        return { count: rows.length }
      } catch(e) {
        console.error(e)
        alert(e.message)
      }
    }
  }

  const download = {
    producer: (producers) => {
      const rows = producers.map(row => ({
        id            : row.id,
        account_id    : row.account.id,
        name          : row.name,
        status        : row.status || 'draft',
        zipcode       : row.zipcode,
        prefecture    : row.prefecture,
        address1      : row.address1,
        address2      : row.address2,
        building      : row.building,
        email         : row.email,
        email_avail   : row.email_avail,
        phone         : row.phone,
        phone_avail   : row.phone_avail,
        fax           : row.fax,
        fax_avail     : row.fax_avail,
        website       : row.website,
        order_method  : row.order_method,
        catch_copy    : row.catch_copy,
        nearby_info   : row.nearby_info,
        description   : row.description,
        product_detail: row.product_detail,
        message       : row.message,
        categories    : row.categories.map(cat => cat.id).join(','),
      }))

      onDownload(Papa.unparse(rows, {
        header: true,
        quotes: true,
      }), `producers-${dayjs().unix()}.csv`)
    },

    template: {
      producer: () => {
        const row = {
          id            : '',
          status        : '',
          account_id    : '',
          categories    : '',
          name          : '',
          zipcode       : '',
          prefecture    : '',
          address1      : '',
          address2      : '',
          building      : '',
          email         : '',
          email_avail   : '',
          phone         : '',
          phone_avail   : '',
          fax           : '',
          fax_avail     : '',
          website       : '',
          order_method  : '',
          catch_copy    : '',
          nearby_info   : '',
          description   : '',
          product_detail: '',
          message       : '',
        }

        onDownload(Papa.unparse([row], {
          header: true,
          quotes: true,
        }), `template-producers-${dayjs().unix()}.csv`)
      }
    }
  }

  return {
    provide: {
      csv: {
        upload,
        download,
      }
    }
  }
})

function onDownload(data, filename) {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom, data], { type: "text/csv" })

  const link = document.createElement('a')
  link.download = filename
  link.href = URL.createObjectURL(blob)

  link.click()
}

async function parse(file) {
  const buff = await file2arrayBuffer(file)
  const data = new Uint8Array(buff)
  const MIME = chardet.detect(data)
  const conv = iconv.decode(data, MIME)

  return new Promise((resolve, reject) => {
    Papa.parse(conv.toString(), {
      header: true,
      skipEmptyLines: true,
      complete: ({ data:csv }) => resolve(csv),
      error: (e) => reject(e)
    })
  })

  function file2arrayBuffer(file) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error('Error reading file'));
      reader.readAsArrayBuffer(file);
    });
  }
}