const data = ref();
export function useAuth() {
  const tokens  = useCookie('tokens', {
    maxAge: 24 * 60 * 60 // 24h
  });

  const signIn = async (credentials, redirectTo) => {
    const route = useRoute()
    return await $fetch(`/api/auth/login`, {
      method: 'POST',
      body: credentials
    }).then(resp => {
      data.value = resp.payload
      tokens.value = {
        accessToken : resp.accessToken,
        refreshToken: resp.refreshToken,
      }

      return navigateTo(route.query.redirect_to || redirectTo || '/');
    })
  }

  const signOut = (redirectPath) => {
    try {
      // Cookieに保存されているトークンを削除
      // const tokens = useCookie('tokens');
      tokens.value = null;  // Cookieを削除するためnullを代入

      // ローカルで保持している認証情報やアカウント情報もクリア
      const account = useState('account');
      account.value = undefined;
      data.value = undefined;

      // ログアウトが完了したら指定のリダイレクト先へ移動
      return navigateTo(redirectPath);
    } catch(e) {
      console.error(e, e.message)
    }
    // data.value    = undefined
    // tokens.value  = {}
    // account.value = undefined

    // location?.replace(redirectPath || '/login')
  }

  const signUp = (credentials, option = {}) => {
    return new Promise((resolve, reject) => {
      $fetch(`/api/auth/regist`, {
        method: 'POST',
        body: credentials
      }).then(resp => {
        resolve(resp)
      }).catch(reject)
    })
  }

  const resetPassword = (body) => {
    return new Promise((resolve, reject) => {
      $fetch(`/api/auth/password/reset`, {
        method: 'POST',
        body,
      }).then(resolve).catch(reject)
    })
  }

  /**
   * パスワード変更
   * @param {String} password - パスワード
   * @param {String} token - JWToken
   * @returns 
   */
  const changePassword = (password, token) => {
    return new Promise((resolve, reject) => {
      $fetch(`/api/auth/password/change`, {
        method: 'POST',
        body: { password },
        headers: { 'Authorization': 'Bearer '+token }
      }).then(resolve).catch(reject)
    })
  }

  const refresh = () => {
    return new Promise((resolve, reject) => {
      $fetch(`/api/auth/refresh`, {
        method: 'POST',
        headers: { Authorization: ['Bearer', tokens.value?.refreshToken].join(' ')}
      }).then(resp => {
        data.value = resp.payload
        tokens.value = {
          accessToken : resp.accessToken,
          refreshToken: resp.refreshToken,
        }
        resolve(resp)
      }).catch(e => {
        console.error(e, e.message)
        reject(e)
      })
    })
  }

  const verify = async (token) => {
    return new Promise((resolve, reject) => {
      $fetch(`/api/auth/verify`, {
        method: 'POST',
        headers: { Authorization: ['Bearer', token].join(' ')}
      }).then(resolve).catch(reject)
    })
  }

  const isAuthenticated = computed(() => !!Object.keys(tokens.value).length);

  return {
    data: readonly(data),
    tokens: readonly(tokens),
    signIn,
    signOut,
    signUp,
    refresh,
    resetPassword,
    changePassword,
    verify,
    isAuthenticated,
  };
}
