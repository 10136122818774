import { default as completez42JnFgtkzMeta } from "/usr/src/app/src/pages/account/recover/complete.vue?macro=true";
import { default as indexkLzySxaZLmMeta } from "/usr/src/app/src/pages/account/recover/index.vue?macro=true";
import { default as complete4T5b2IHgv2Meta } from "/usr/src/app/src/pages/account/recover/password/complete.vue?macro=true";
import { default as indexhUuyOpwmEFMeta } from "/usr/src/app/src/pages/account/recover/password/index.vue?macro=true";
import { default as completecvY1ayL39yMeta } from "/usr/src/app/src/pages/account/regist/complete.vue?macro=true";
import { default as confirmge29JzvcmxMeta } from "/usr/src/app/src/pages/account/regist/confirm.vue?macro=true";
import { default as index1WUbrtE8V6Meta } from "/usr/src/app/src/pages/account/regist/index.vue?macro=true";
import { default as _91id_931gGTrXelGGMeta } from "/usr/src/app/src/pages/admin/account/[id].vue?macro=true";
import { default as indexuz0yf4UdYOMeta } from "/usr/src/app/src/pages/admin/account/index.vue?macro=true";
import { default as _91id_93UIkNX3KDyeMeta } from "/usr/src/app/src/pages/admin/category/[id].vue?macro=true";
import { default as indexLi9TjVO35UMeta } from "/usr/src/app/src/pages/admin/category/index.vue?macro=true";
import { default as indexa38kFMt8JwMeta } from "/usr/src/app/src/pages/admin/gallery/index.vue?macro=true";
import { default as indexl9bCI5ej4BMeta } from "/usr/src/app/src/pages/admin/index.vue?macro=true";
import { default as login1Z3dOWh9ZCMeta } from "/usr/src/app/src/pages/admin/login.vue?macro=true";
import { default as _91id_93wFZTzhYpleMeta } from "/usr/src/app/src/pages/admin/page/[id].vue?macro=true";
import { default as indextx5zJS2Qg5Meta } from "/usr/src/app/src/pages/admin/page/index.vue?macro=true";
import { default as _91id_93BTW4KirZy3Meta } from "/usr/src/app/src/pages/admin/producer/[id].vue?macro=true";
import { default as indexcSuXPTSh3bMeta } from "/usr/src/app/src/pages/admin/producer/index.vue?macro=true";
import { default as accountbrRZyJ9aWhMeta } from "/usr/src/app/src/pages/admin/setting/account.vue?macro=true";
import { default as agreementLzM9EhNUcFMeta } from "/usr/src/app/src/pages/admin/setting/agreement.vue?macro=true";
import { default as drawer_45linksu5xWG4f4H8Meta } from "/usr/src/app/src/pages/admin/setting/drawer-links.vue?macro=true";
import { default as emailrycfPcTOmiMeta } from "/usr/src/app/src/pages/admin/setting/email.vue?macro=true";
import { default as indexaoiN5rMaeUMeta } from "/usr/src/app/src/pages/admin/setting/index.vue?macro=true";
import { default as paymentplT7u28y1JMeta } from "/usr/src/app/src/pages/admin/setting/payment.vue?macro=true";
import { default as tagBN9EVzO4NZMeta } from "/usr/src/app/src/pages/admin/tag.vue?macro=true";
import { default as _91provider_93pLrj3G906kMeta } from "/usr/src/app/src/pages/callback/[provider].vue?macro=true";
import { default as completerliwbbTvQsMeta } from "/usr/src/app/src/pages/contact/complete.vue?macro=true";
import { default as index3XC6HjPlM3Meta } from "/usr/src/app/src/pages/contact/index.vue?macro=true";
import { default as indexYu3DynwDClMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as login2t1QMQfxAxMeta } from "/usr/src/app/src/pages/login.vue?macro=true";
import { default as favoritejg9skd3L8SMeta } from "/usr/src/app/src/pages/mypage/favorite.vue?macro=true";
import { default as loginoiXVp6qrQqMeta } from "/usr/src/app/src/pages/mypage/history/login.vue?macro=true";
import { default as paidAZbhBxRGmQMeta } from "/usr/src/app/src/pages/mypage/history/paid.vue?macro=true";
import { default as indexmOQQoqIoETMeta } from "/usr/src/app/src/pages/mypage/index.vue?macro=true";
import { default as infowJ1IG6aKGYMeta } from "/usr/src/app/src/pages/mypage/info.vue?macro=true";
import { default as _91slug_93GtjEXgdL9AMeta } from "/usr/src/app/src/pages/page/[slug].vue?macro=true";
import { default as detail0kZvKedjGYMeta } from "/usr/src/app/src/pages/producer/[cuid]/detail.vue?macro=true";
import { default as index0WC0RNetiMMeta } from "/usr/src/app/src/pages/producer/[cuid]/index.vue?macro=true";
import { default as indexuBQrfCUxNeMeta } from "/usr/src/app/src/pages/producer/index.vue?macro=true";
import { default as _91slug_93Lw2rnjMT49Meta } from "/usr/src/app/src/pages/pub/[slug].vue?macro=true";
import { default as indexkrbTm4am9CMeta } from "/usr/src/app/src/pages/recover/index.vue?macro=true";
import { default as _01companya2QuF4z2uyMeta } from "/usr/src/app/src/pages/setup/01company.vue?macro=true";
import { default as _02storage0LVl7BsfN0Meta } from "/usr/src/app/src/pages/setup/02storage.vue?macro=true";
import { default as _03siteJ1tNV666T1Meta } from "/usr/src/app/src/pages/setup/03site.vue?macro=true";
import { default as _04smtpdbBNGjJUGpMeta } from "/usr/src/app/src/pages/setup/04smtp.vue?macro=true";
import { default as _05account6JltxhNbNTMeta } from "/usr/src/app/src/pages/setup/05account.vue?macro=true";
import { default as _06confirme4s5JXUk3MMeta } from "/usr/src/app/src/pages/setup/06confirm.vue?macro=true";
import { default as completeGCgaXun1kmMeta } from "/usr/src/app/src/pages/setup/complete.vue?macro=true";
import { default as indexijx5SHb3QRMeta } from "/usr/src/app/src/pages/setup/index.vue?macro=true";
export default [
  {
    name: "account-recover-complete",
    path: "/account/recover/complete",
    meta: completez42JnFgtkzMeta || {},
    component: () => import("/usr/src/app/src/pages/account/recover/complete.vue")
  },
  {
    name: "account-recover",
    path: "/account/recover",
    meta: indexkLzySxaZLmMeta || {},
    component: () => import("/usr/src/app/src/pages/account/recover/index.vue")
  },
  {
    name: "account-recover-password-complete",
    path: "/account/recover/password/complete",
    meta: complete4T5b2IHgv2Meta || {},
    component: () => import("/usr/src/app/src/pages/account/recover/password/complete.vue")
  },
  {
    name: "account-recover-password",
    path: "/account/recover/password",
    meta: indexhUuyOpwmEFMeta || {},
    component: () => import("/usr/src/app/src/pages/account/recover/password/index.vue")
  },
  {
    name: "account-regist-complete",
    path: "/account/regist/complete",
    meta: completecvY1ayL39yMeta || {},
    component: () => import("/usr/src/app/src/pages/account/regist/complete.vue")
  },
  {
    name: "account-regist-confirm",
    path: "/account/regist/confirm",
    meta: confirmge29JzvcmxMeta || {},
    component: () => import("/usr/src/app/src/pages/account/regist/confirm.vue")
  },
  {
    name: "account-regist",
    path: "/account/regist",
    meta: index1WUbrtE8V6Meta || {},
    component: () => import("/usr/src/app/src/pages/account/regist/index.vue")
  },
  {
    name: "admin-account-id",
    path: "/admin/account/:id()",
    meta: _91id_931gGTrXelGGMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/account/[id].vue")
  },
  {
    name: "admin-account",
    path: "/admin/account",
    meta: indexuz0yf4UdYOMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/account/index.vue")
  },
  {
    name: "admin-category-id",
    path: "/admin/category/:id()",
    meta: _91id_93UIkNX3KDyeMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/category/[id].vue")
  },
  {
    name: "admin-category",
    path: "/admin/category",
    meta: indexLi9TjVO35UMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/category/index.vue")
  },
  {
    name: "admin-gallery",
    path: "/admin/gallery",
    meta: indexa38kFMt8JwMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/gallery/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexl9bCI5ej4BMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: login1Z3dOWh9ZCMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/login.vue")
  },
  {
    name: "admin-page-id",
    path: "/admin/page/:id()",
    meta: _91id_93wFZTzhYpleMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/page/[id].vue")
  },
  {
    name: "admin-page",
    path: "/admin/page",
    meta: indextx5zJS2Qg5Meta || {},
    component: () => import("/usr/src/app/src/pages/admin/page/index.vue")
  },
  {
    name: "admin-producer-id",
    path: "/admin/producer/:id()",
    meta: _91id_93BTW4KirZy3Meta || {},
    component: () => import("/usr/src/app/src/pages/admin/producer/[id].vue")
  },
  {
    name: "admin-producer",
    path: "/admin/producer",
    meta: indexcSuXPTSh3bMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/producer/index.vue")
  },
  {
    name: "admin-setting-account",
    path: "/admin/setting/account",
    component: () => import("/usr/src/app/src/pages/admin/setting/account.vue")
  },
  {
    name: "admin-setting-agreement",
    path: "/admin/setting/agreement",
    meta: agreementLzM9EhNUcFMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/setting/agreement.vue")
  },
  {
    name: "admin-setting-drawer-links",
    path: "/admin/setting/drawer-links",
    meta: drawer_45linksu5xWG4f4H8Meta || {},
    component: () => import("/usr/src/app/src/pages/admin/setting/drawer-links.vue")
  },
  {
    name: "admin-setting-email",
    path: "/admin/setting/email",
    meta: emailrycfPcTOmiMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/setting/email.vue")
  },
  {
    name: "admin-setting",
    path: "/admin/setting",
    meta: indexaoiN5rMaeUMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/setting/index.vue")
  },
  {
    name: "admin-setting-payment",
    path: "/admin/setting/payment",
    meta: paymentplT7u28y1JMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/setting/payment.vue")
  },
  {
    name: "admin-tag",
    path: "/admin/tag",
    meta: tagBN9EVzO4NZMeta || {},
    component: () => import("/usr/src/app/src/pages/admin/tag.vue")
  },
  {
    name: "callback-provider",
    path: "/callback/:provider()",
    component: () => import("/usr/src/app/src/pages/callback/[provider].vue")
  },
  {
    name: "contact-complete",
    path: "/contact/complete",
    component: () => import("/usr/src/app/src/pages/contact/complete.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/usr/src/app/src/pages/contact/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login2t1QMQfxAxMeta || {},
    component: () => import("/usr/src/app/src/pages/login.vue")
  },
  {
    name: "mypage-favorite",
    path: "/mypage/favorite",
    meta: favoritejg9skd3L8SMeta || {},
    component: () => import("/usr/src/app/src/pages/mypage/favorite.vue")
  },
  {
    name: "mypage-history-login",
    path: "/mypage/history/login",
    component: () => import("/usr/src/app/src/pages/mypage/history/login.vue")
  },
  {
    name: "mypage-history-paid",
    path: "/mypage/history/paid",
    component: () => import("/usr/src/app/src/pages/mypage/history/paid.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexmOQQoqIoETMeta || {},
    component: () => import("/usr/src/app/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-info",
    path: "/mypage/info",
    meta: infowJ1IG6aKGYMeta || {},
    component: () => import("/usr/src/app/src/pages/mypage/info.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/usr/src/app/src/pages/page/[slug].vue")
  },
  {
    name: "producer-cuid-detail",
    path: "/producer/:cuid()/detail",
    component: () => import("/usr/src/app/src/pages/producer/[cuid]/detail.vue")
  },
  {
    name: "producer-cuid",
    path: "/producer/:cuid()",
    component: () => import("/usr/src/app/src/pages/producer/[cuid]/index.vue")
  },
  {
    name: "producer",
    path: "/producer",
    component: () => import("/usr/src/app/src/pages/producer/index.vue")
  },
  {
    name: "pub-slug",
    path: "/pub/:slug()",
    meta: _91slug_93Lw2rnjMT49Meta || {},
    component: () => import("/usr/src/app/src/pages/pub/[slug].vue")
  },
  {
    name: "recover",
    path: "/recover",
    component: () => import("/usr/src/app/src/pages/recover/index.vue")
  },
  {
    name: "setup-01company",
    path: "/setup/01company",
    meta: _01companya2QuF4z2uyMeta || {},
    component: () => import("/usr/src/app/src/pages/setup/01company.vue")
  },
  {
    name: "setup-02storage",
    path: "/setup/02storage",
    meta: _02storage0LVl7BsfN0Meta || {},
    component: () => import("/usr/src/app/src/pages/setup/02storage.vue")
  },
  {
    name: "setup-03site",
    path: "/setup/03site",
    meta: _03siteJ1tNV666T1Meta || {},
    component: () => import("/usr/src/app/src/pages/setup/03site.vue")
  },
  {
    name: "setup-04smtp",
    path: "/setup/04smtp",
    meta: _04smtpdbBNGjJUGpMeta || {},
    component: () => import("/usr/src/app/src/pages/setup/04smtp.vue")
  },
  {
    name: "setup-05account",
    path: "/setup/05account",
    meta: _05account6JltxhNbNTMeta || {},
    component: () => import("/usr/src/app/src/pages/setup/05account.vue")
  },
  {
    name: "setup-06confirm",
    path: "/setup/06confirm",
    meta: _06confirme4s5JXUk3MMeta || {},
    component: () => import("/usr/src/app/src/pages/setup/06confirm.vue")
  },
  {
    name: "setup-complete",
    path: "/setup/complete",
    meta: completeGCgaXun1kmMeta || {},
    component: () => import("/usr/src/app/src/pages/setup/complete.vue")
  },
  {
    name: "setup",
    path: "/setup",
    meta: indexijx5SHb3QRMeta || {},
    component: () => import("/usr/src/app/src/pages/setup/index.vue")
  }
]