import libPhone from 'google-libphonenumber'
const FormatUtil = (value) => {

  // 日本の国コード
  const region = 'JP';

  const util = libPhone.PhoneNumberUtil.getInstance();

  // 番号と地域を設定
  const number = util.parseAndKeepRawInput(value, region);

  // 電話番号の有効性チェック
  if (!util.isValidNumberForRegion(number, region)) return value;

  // ハイフン付きの形式で返却
  return util.format(number, libPhone.PhoneNumberFormat.NATIONAL);
}

export default FormatUtil