export default defineNuxtPlugin(() => {
  const toasts = useState('toast')

  const remove = (index) => {
    toasts.value.list[index].visible = false
    setTimeout(() => {
      toasts.value.list[index].delete = true
    }, 1000);
  }

  const add = (message, type = 'info', timer = 2000) => {
    if (!toasts.value.list) toasts.value.list = []
    const index = toasts.value.list.length
    const totalVisible = toasts.value.list.filter(toast => toast.visible).length + 1
    toasts.value.list.push({
      id: toasts.value.counter++,
      message,
      type,
      timeOut: timer * totalVisible,
      visible: true,
      delete: false,
    })
    setTimeout(() => remove(index), timer * totalVisible)
  }

  return {
    provide: {
      toast: { add, remove }
    }
  }
})