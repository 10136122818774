import qs     from 'qs'
import ejs    from 'ejs'
import {v4 as uuid} from 'uuid'

import dayjs  from '@/utils/dayjs'
import zipcode from '@/utils/zipcode'
import phoneFormat from '@/utils/phone-format'

export default defineNuxtPlugin(() => {

  return {
    provide: {
      uuid,
      dayjs,
      renderEjs: (template, data)  => ejs.render(template, data),

      qs: {
        parse: function(str) {
          if (!str) return {}
          str = str.replace('?', '')
          return qs.parse(str)
        },
        stringify: function(obj = {}) {
          if (!obj) return null
          return qs.stringify(obj)
        }
      },
      zeroPadding: (number, length) => String(number).padStart(length, '0'),

      rand: (length = 12) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      },
      zipcode,
      phoneFormat,
    }
  }
})