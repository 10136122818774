import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'

const confirmText = "入力内容は破棄されます、本当に移動してもよろしいですか?"

const confirmBrowserRouter = (e) => {
  e.preventDefault()
  e.returnValue = confirmText
}

const confirmVueRouter = (next) => {
  const answer = window.confirm(confirmText)
  if (answer) {
    // ページを移動したら、確認ダイアログを表示しないよう戻す
    window.removeEventListener("beforeunload", confirmBrowserRouter)
    next()
  }
}

const useConfirmUnload = () => {
  // Vue-routerでのページ移動時の確認ダイアログ発火
  onBeforeRouteLeave((_to, _from, next) => {
    confirmVueRouter(next)
  })
  onBeforeRouteUpdate((_to, _from, next) => {
    confirmVueRouter(next)
  })
  // ページリロード時の確認ダイアログ発火

  if (process.client) {
    window.addEventListener("beforeunload", confirmBrowserRouter)
  }
}

// beforeunloadイベントリスナーを削除する関数
const removeConfirmUnload = () => {
  if (process.client) {
    window.removeEventListener("beforeunload", confirmBrowserRouter)
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      useConfirmUnload,
      removeConfirmUnload,
    }
  }
})