export default defineNuxtPlugin(() => {
  return {
    provide: {
      text2html: (str) => {
        if (!str) return ''

        switch(true) {
          case isHtml(str): return str;
          case isMarkDown(str): return str;
          default: return str.replace(/\n/g, '<br>');
        }

        function isHtml (str) {
          return /<[a-z][\s\S]*>/i.test(str)
        }

        function isMarkDown (str) {
          return [
            /^#+\s/,        // ヘッダ
            /\*\*[^*]+\*\*/, // bold
            /\*[^\*]+\*/,   // italic
            /\!\[.*\]\(.*\)/, // 画像
            /\[.*\]\(.*\)/   // リンク
          ].some(pattern => pattern.test(str));
        }
      }
    }
  }
})