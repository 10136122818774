

import dayjs  from 'dayjs'
import 'dayjs/locale/ja'
import objectSupport  from 'dayjs/plugin/objectSupport'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(objectSupport);
dayjs.locale('ja')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Tokyo")

const $dayjs = (date = null) => dayjs(date || dayjs())

export default $dayjs