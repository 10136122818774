export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute()
  nuxtApp.hook('app:error', (error) => {
    console.log('plugins/custom-error', error)
    if (error.statusCode === 401) {
      console.log(route)
      return navigateTo(route);
    }
    // 他のエラーの場合は通常のエラーページを表示
    nuxtApp.payload.error = error;
  });
});
