export function useDatabase() {
  const { tokens } = useAuth();
  const headers = { authorization: 'Bearer '+(tokens.value?.accessToken || '') }

  // ===================================================================
  // 設定情報
  // ===================================================================
  /** **************************************
   * サイト情報取得
   * @param {String} type
   ************************************** */
  const checkSetting = async () => {
    try {
      const { data, error } = await useFetch('/api/v1/master/setting/check');
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * サイト情報取得
   * @param {String} type
   ************************************** */
  const getSettings = async (type = '', array = false) => {
    try {
      const query = array ? { array } : {};
      const { data, error } = await useFetch(`/api/v1/master/setting/${type}`, {
        query, headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * サイト情報更新
   * @param {Array} body
   ************************************** */
  const upsertSettings = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/master/setting', {
        method: 'PUT',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * サイト情報削除
   * @param {String|Number} id - レコードID
   ************************************** */
  const deleteSetting = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/master/setting/${id}`, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };


  // ===================================================================
  // サイト情報
  // ===================================================================

  /** **************************************
   * サイト情報取得
   * @param {String} type
   ************************************** */
  const getMaster = async (type = '') => {
    try {
      const { data, error } = await useFetch(`/api/v1/master/system/${type}`);
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * サイト情報更新
   * @param {Array} body
   ************************************** */
  const upsertMaster = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/master/system', {
        method: 'PUT',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };


  // ===================================================================
  // 会員情報
  // ===================================================================
  /** **************************************
   * 会員情報取得(1件)
   * @param {Number|String} id - 会員ID
   * @returns {Object}
   ************************************** */
  const getAccount = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/account/${id || 'me'}`, { headers });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 会員情報取得(複数)
   * @param {Object} query - 検索クエリ
   * @returns {Array}
   ************************************** */
  const getAccounts = async (query = {}) => {
    try {
      const { data, error } = await useFetch('/api/v1/account', {
        headers,
        query
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 会員情報更新
   * @param {Number|String} id - 会員ID
   * @param {Object} body - 会員データ
   * @returns {Object}
   ************************************** */
  const updateAccount = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/account/${id || 'me'}`, {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };


  // ===================================================================
  // 生産者タグ情報
  // ===================================================================
  const getTags = async (query) => {
    try {
      const { data, error } = await useFetch('/api/v1/tag', { headers, query });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  const addTag = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/tag', {
        method: 'POST',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  const updateTags = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/tag', {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  const updateTag = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/tag/${id}`, {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  const deleteTag = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/tag/${id}`, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };


  // ===================================================================
  // 生産者カテゴリ情報
  // ===================================================================
  /** **************************************
   * 商品カテゴリ取得
   * @param {Number|String} parentId - 親カテゴリID
   * @returns {Array}
   ************************************** */
  const getCategories = async () => {
    try {
      const { data, error } = await useFetch('/api/v1/category');
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 商品子カテゴリ取得
   * @param {Number|String} parentId - 親カテゴリID
   * @returns {Array}
   ************************************** */
  const getCategoryChildren = async (parentId = 0) => {
    try {
      const { data, error } = await useFetch(`/api/v1/category/parent/${parentId}`);
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      console.error(e,e.message)
      throw e;
    }
  };

  /** **************************************
   * 商品カテゴリ登録
   * @param {Object} body - カテゴリオブジェクト
   * @returns {Object}
   ************************************** */
  const addCategory = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/category', {
        method: 'POST',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 商品カテゴリ更新(1件)
   * @param {Number|String} id - カテゴリID
   * @param {Object} body - カテゴリオブジェクト
   * @returns {Object}
   ************************************** */
  const updateCategory = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/category/${id}`, {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 商品カテゴリ更新(複数)
   * @param {Object} body - カテゴリ配列
   * @returns {Object}
   ************************************** */
  const updateCategories = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/category', {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 商品カテゴリ削除
   * @param {Number|String} id - カテゴリID
   * @returns {Object}
   ************************************** */
  const deleteCategory = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/category/${id}`, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };


  // ===================================================================
  // 生産者情報
  // ===================================================================
  /** **************************************
   * 生産者取得(複数)
   * @param {Object} query - 検索クエリ
   * @returns {Array}
   ************************************** */
  const getProducers = async (query) => {
    try {
      const { data, error } = await useFetch('/api/v1/producer', { query, headers });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 生産者取得(スライド用)
   * @param {Object} query - 検索クエリ
   * @returns {Array}
   ************************************** */
  const getProducersForSlider = async (query) => {
    try {
      const { data, error } = await useFetch('/api/v1/producer/for-slider', { headers });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 生産者情報取得(1件)
   * @param {Number|String} id - 生産者ID
   * @returns {Object}
   ************************************** */
  const getProducer = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/producer/${id}`, { headers });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 生産者情報登録または更新(1件または複数)
   * @param {Object} items    - 生産者情報(配列の場合複数)
   * @param {Object} multiple - 複数登録
   * @returns {Object}
   ************************************** */
  const addProducer = async (items, multiple = false) => {
    try {
      const { data, error } = await useFetch('/api/v1/producer', {
        method: 'POST',
        headers,
        body: { items, multiple }
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 生産者情報更新
   * @param {Number|String} id - 生産者ID
   * @param {Object} body - 生産者情報
   * @returns {Object}
   ************************************** */
  const updateProducer = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/producer/${id}`, {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 生産者情報削除
   * @param {Number|String} id - 生産者ID
   * @returns {Object}
   ************************************** */
  const deleteProducer = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/producer/${id}`, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 生産者キャッチコピー生成
   * @param {Number|String} id - 生産者ID
   * @returns {Object}
   ************************************** */
  const generateProducerCatchCopy = async (id = 'me') => {
    try {
      const { data, error } = await useFetch(`/api/v1/producer/catchcopy/${id}`, {
        method: 'POST',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };



  // ===================================================================
  // 検査結果情報
  // ===================================================================
  /** **************************************
   * 検査結果情報取得(複数)
   * @param {Number} producerId - 生産者ID
   * @returns {Array}
   ************************************** */
  const getInspections = async (producerId) => {
    try {
      const { data, error } = await useFetch('/api/v1/inspection', {
        query: { producer_id: producerId }
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 検査結果情報更新(複数)
   * @param {Number} producer_id - 生産者ID
   * @param {Array} items - 検査結果情報
   * @returns {Array}
   ************************************** */
  const upsertInspections = async (producer_id, items) => {
    try {
      const { data, error } = await useFetch('/api/v1/inspection', {
        method: 'PUT',
        headers,
        body: { producer_id, items }
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  // ===================================================================
  // ページ情報
  // ===================================================================
  /** **************************************
   * ページ取得(複数)
   * @param {Object} query - 検索クエリ
   * @returns {Array}
   ************************************** */
  const getPages = async (query) => {
    try {
      const { data, error } = await useFetch('/api/v1/page', {
        query,
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ページ取得(1件)
   * @param {Number|String} idOrSlug - ページID or スラッグ
   * @returns {Object}
   ************************************** */
  const getPage = async (idOrSlug) => {
    try {
      const { data, error } = await useFetch(`/api/v1/page/${idOrSlug}`, {
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ページ登録(1件)
   * @param {Object} body - ページ情報
   * @returns {Object}
   ************************************** */
  const addPage = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/page', {
        method: 'POST',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ページ更新
   * @param {Number|String} id - ページID
   * @param {Object} body - ページ情報
   * @returns {Object}
   ************************************** */
  const updatePage = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/page/${id}`, {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ページ削除
   * @param {Number} id - ページID
   * @returns {Object}
   ************************************** */
  const deletePage = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/page/${id}`, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  // ===================================================================
  // お気に入り生産者
  // ===================================================================
  /** **************************************
   * お気に入り生産者取得(１件)
   * @returns {Number}
   ************************************** */
  const getFavorite = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/favorite/${id}`, {
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * お気に入り生産者取得(すべて)
   * @returns {Array}
   ************************************** */
  const getFavorites = async (query = {}) => {
    try {
      const { data, error } = await useFetch('/api/v1/favorite', {
        query,
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * お気に入り生産者追加
   * @param {Number} id - 生産者ID
   * @returns {Object}
   ************************************** */
  const addFavorite = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/favorite/${id}`, {
        method: 'POST',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * お気に入り生産者削除
   * @param {Number} id - お気に入りID
   * @returns {Object}
   ************************************** */
  const deleteFavorite = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/favorite/${id}`, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };


  // ===================================================================
  // プラン
  // ===================================================================
  /** **************************************
   * プラン追加
   * @param {Object} body - プラン情報
   * @returns {Object}
   ************************************** */
  const getPlans = async (body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/plan`, {
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * プラン追加
   * @param {Object} body - プラン情報
   * @returns {Object}
   ************************************** */
  const addPlan = async (body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/plan`, {
        method: 'POST',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * プラン更新
   * @param {Object} body - プラン情報
   * @returns {Object}
   ************************************** */
  const updatePlan = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/plan/` + id, {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      console.log(e, e.message);
      throw e;
    }
  };

  /** **************************************
   * 支払追加
   * @param {Object} body - 支払情報オブジェクト
   * @returns {Object}
   ************************************** */
  const addPayment = async (body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/payment`, {
        method: 'POST',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 支払更新
   * @param {Number} id - 注文ID
   * @param {Object} body - 支払情報オブジェクト
   * @returns {Object}
   ************************************** */
  const updatePayment = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/payment/${id}`, {
        method: 'PATCH',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ポイント取得
   * @param {Number|String} id - 会員ID
   * @returns {Object}
   ************************************** */
  const getPoint = async (idOrQuery, query = {}) => {
    if (idOrQuery && typeof idOrQuery == 'object') {
      query = idOrQuery;
      idOrQuery = undefined;
    }
    try {
      const { data, error } = await useFetch(`/api/v1/point/${idOrQuery || 'me'}`, {
        headers,
        query
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ポイント追加
   * @param {Number|String} id - 会員ID
   * @param {Object} body - ポイント詳細
   * @returns {Object}
   ************************************** */
  const addPoint = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/point/${id}/add`, {
        method: 'POST',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ポイント減少
   * @param {Number|String} id - 会員ID
   * @param {Object} body - ポイント詳細
   * @returns {Object}
   ************************************** */
  const subtractPoint = async (id, body) => {
    try {
      const { data, error } = await useFetch(`/api/v1/point/${id || 'me'}/subtract`, {
        method: 'POST',
        headers,
        body
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ポイント削除(払い戻し)
   * @param {Number|String} id - ポイントID
   * @returns {Object}
   ************************************** */
  const deletePoint = async (id) => {
    try {
      const { data, error } = await useFetch(`/api/v1/point/${id}`, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ファイル取得
   * @param {String} url - ファイルのURL
   * @returns {Object}
   ************************************** */
  const getFile = async (url) => {
    try {
      const { data, error } = await useFetch(`/api/v1/file`, {
        headers,
        query: { url }
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ファイル取得(複数)
   * @param {String} type - ファイルの種類
   * @returns {Object}
   ************************************** */
  const getFiles = async (type) => {
    try {
      const { data, error } = await useFetch(`/api/v1/file/all`, {
        headers,
        query: { type }
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ファイルアップロード(複数)
   * @param {Array} files - ファイルオブジェクトの配列
   * @returns {Object}
   ************************************** */
  const uploadFiles = async (files) => {
    try {
      const formData = new FormData();
      files.forEach(file => formData.append("files", file));

      const { data, error } = await useFetch('/api/v1.1/file', {
        method: 'POST',
        body: formData,
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ファイルアップロード
   * @param {Object} file - ファイルオブジェクト
   * @param {String} path - ファイルパス
   * @returns {Object}
   ************************************** */
  const uploadFile = async (file, path) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", path);

      const { data, error } = await useFetch('/api/v1/file', {
        method: 'POST',
        body: formData,
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ファイル削除
   * @param {Number|String} id - ファイルID
   * @returns {Object}
   ************************************** */
  const deleteFile = async (id) => {
    try {
      const { data, error } = await useFetch('/api/v1/file/' + id, {
        method: 'DELETE',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * ファイル削除(複数)
   * @param {Array} ids - ファイルIDを含む配列
   * @returns {Object}
   ************************************** */
  const deleteFiles = async (ids = []) => {
    try {
      const { data, error } = await useFetch('/api/v1/file/', {
        method: 'DELETE',
        headers,
        query: { ids }
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 問い合わせ送信
   * @param {Object} body - メール内容
   * @param {String} body.subject - 件名
   * @param {String} body.contents - 内容
   ************************************** */
  const sendContact = async (body) => {
    try {
      const { data, error } = await useFetch('/api/v1/contact', {
        method: 'POST',
        body,
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * メールテンプレート取得(1件)
   * @param {Number} id - テンプレートID
   * @returns {Object}
   ************************************** */
  const getEmailTemplate = async (id) => {
    try {
      const { data, error } = await useFetch('/api/v1/email-template/' + id, {
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * メールテンプレート取得(複数)
   * @returns {Array}
   ************************************** */
  const getEmailTemplates = async () => {
    try {
      const { data, error } = await useFetch('/api/v1/email-template', {
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * メールテンプレート更新
   * @param {Number} id   - テンプレートID
   * @param {Object} body - テンプレート情報
   * @returns {Object}
   ************************************** */
  const updateEmailTemplate = async (id, body) => {
    try {
      const { data, error } = await useFetch('/api/v1/email-template/' + id, {
        method: 'PATCH',
        body,
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 閲覧履歴取得
   * @param {String} cuid - ユーザーID
   * @returns {Object}
   ************************************** */
  const getBrowsingHistory = async (cuid) => {
    try {
      const { data, error } = await useFetch('/api/v1/browsing-history/' + cuid, {
        method: 'GET',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };

  /** **************************************
   * 閲覧履歴追加
   * @param {String} cuid - ユーザーID
   * @returns {Object}
   ************************************** */
  const addBrowsingHistory = async (cuid) => {
    try {
      const { data, error } = await useFetch('/api/v1/browsing-history/' + cuid, {
        method: 'POST',
        headers
      });
      if (error.value) throw error.value;
      return data.value;
    } catch (e) {
      throw e;
    }
  };


  return {
    checkSetting,
    getSettings,
    upsertSettings,
    deleteSetting,
    getMaster,
    upsertMaster,
    getAccount,
    getAccounts,
    updateAccount,
    getTags,
    addTag,
    updateTags,
    updateTag,
    deleteTag,
    getCategories,
    getCategoryChildren,
    addCategory,
    updateCategory,
    updateCategories,
    deleteCategory,
    getProducers,
    getProducersForSlider,
    getProducer,
    addProducer,
    updateProducer,
    deleteProducer,
    generateProducerCatchCopy,
    getInspections,
    upsertInspections,
    getPages,
    getPage,
    addPage,
    updatePage,
    deletePage,
    getFavorite,
    getFavorites,
    addFavorite,
    deleteFavorite,
    getPlans,
    addPlan,
    updatePlan,
    addPayment,
    updatePayment,
    getPoint,
    addPoint,
    subtractPoint,
    deletePoint,
    getFile,
    getFiles,
    uploadFile,
    uploadFiles,
    deleteFile,
    deleteFiles,
    sendContact,
    getEmailTemplate,
    getEmailTemplates,
    updateEmailTemplate,
    getBrowsingHistory,
    addBrowsingHistory,
  }
}