import revive_payload_client_SFKXLeon8g from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9U48yemOET from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HE74f0nYZT from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ulJLsqgLew from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EFOp8sVW6s from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_b520EzEa5j from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4v6F9s0yRX from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_DoP12JmhWz from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.55_ioredis@5.4.1_magicast@0.3.5_rollup@4._x46yedmco2uqyafz46vy2niz3a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _components_WukwxGX28z from "/usr/src/app/src/plugins/_components.js";
import csv_WSdgffgnSf from "/usr/src/app/src/plugins/csv.js";
import custom_error_9IuNMZ2sj6 from "/usr/src/app/src/plugins/custom-error.js";
import index_tOuFLbOMhi from "/usr/src/app/src/plugins/index.js";
import phone_S45jYeLLN6 from "/usr/src/app/src/plugins/phone.js";
import scroll_top_7SPbaEvDyQ from "/usr/src/app/src/plugins/scroll-top.js";
import text2html_jB4nb8Gh5g from "/usr/src/app/src/plugins/text2html.js";
import toast_Q3SaoaFNSk from "/usr/src/app/src/plugins/toast.js";
import use_confirm_unload_7geRrrjsTA from "/usr/src/app/src/plugins/use-confirm-unload.js";
export default [
  revive_payload_client_SFKXLeon8g,
  unhead_9U48yemOET,
  router_HE74f0nYZT,
  payload_client_ulJLsqgLew,
  navigation_repaint_client_EFOp8sVW6s,
  check_outdated_build_client_b520EzEa5j,
  chunk_reload_client_4v6F9s0yRX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DoP12JmhWz,
  _components_WukwxGX28z,
  csv_WSdgffgnSf,
  custom_error_9IuNMZ2sj6,
  index_tOuFLbOMhi,
  phone_S45jYeLLN6,
  scroll_top_7SPbaEvDyQ,
  text2html_jB4nb8Gh5g,
  toast_Q3SaoaFNSk,
  use_confirm_unload_7geRrrjsTA
]